import { useState } from "react";
import { GoogleSpreadsheet } from "google-spreadsheet";
import WhatsAppLogo from "../../assets/WA-logo.png";

const initialValues = {
  name: "",
  email: "",
  // isd: "91",
  phone: "",
  city: "",
  state: "",
  note: "",
};

function App() {
  // eslint-disable-next-line
  const [temp, setTemp] = useState("");
  const [values, setValues] = useState(initialValues);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleChange = (e) => {
    let field = e.target.name;
    let data = values;
    data[field] = e.target.value;
    setTemp(e.target.value);
    setValues(data);
  };

  const handleWhatsAppClick = () => {
    const phoneNumber = "+918281446731";
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.open(whatsappUrl, "_blank");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(values);
    setIsLoading(true);

    const SPREADSHEET_ID = process.env.REACT_APP_GL_SPREADSHEET_ID;
    const SHEET_ID = process.env.REACT_APP_GL_SHEET_ID.toString();
    const CLIENT_EMAIL = process.env.REACT_APP_GL_CLIENT_EMAIL;
    const PRIVATE_KEY = process.env.REACT_APP_GL_PRIVATE_KEY;

    const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

    try {
      await doc.useServiceAccountAuth({
        client_email: CLIENT_EMAIL,
        private_key: PRIVATE_KEY,
      });
      // loads document properties and worksheets
      await doc.loadInfo();

      const sheet = doc.sheetsById[SHEET_ID];
      const rows = await sheet.getRows();

      const filtered = rows.filter((e) => e.Email === values.email);

      if (!filtered.length) {
        let date = new Date();

        await sheet.addRow({
          Date: date.toLocaleString(),
          Email: values.email,
          Name: values.name,
          // ISD: values.isdCode,
          Phone: values.phone,
          City: values.city,
          State: values.state,
          Note: values.note,
        });
      }

      setIsLoading(false);
      setIsModalOpen(false);
      setValues(initialValues);
      setTemp("")
      return alert("Data submitted. Thank You.");
    } catch (e) {
      console.log("error", e);
      setIsLoading(false);
      return alert("Failed to submit");
    }
  };

  return (
    <>
      {/* <div style={{ color: "white", padding: "10px" }}>
        <b>about us</b>
      </div> */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "40px",
          minHeight: "90vh",
          textAlign: "center",
          padding: "20px"
        }}
      >
        <div>
          <div
            style={{ color: "white", fontSize: "30px", fontWeight: "500", marginBottom: "40px" }}
          >
            Want something from India?<span style={{ color: "#28fc03" }}>*</span>
          </div>
          <div style={{ marginBottom: "50px", color: "white", fontSize: "23px" }}>
            tell us what. and we will get it.
          </div>
          <div
            style={{
              display: "flex",
              marginBottom: "10px",
              gap: 20,
              justifyContent: "space-between",
            }}
          >
            <div style={{ backgroundColor: "#FEF0CE" }}>kanchipuram silk saree under $250</div>
            <div style={{ backgroundColor: "#D0E1F3" }}>a good clay pot for making fish curry</div>
          </div>
          <div
            style={{
              display: "flex",
              gap: 20,
              justifyContent: "space-between",
              marginBottom: "30px",
            }}
          >
            <div style={{ backgroundColor: "#D9E9D7" }}>
              pick an item from my parent&apos;s place and send it over
            </div>
            <div style={{ backgroundColor: "#EAD0DA" }}>a suit stitched for my son</div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <input
              type="text"
              name="note"
              value={values.note}
              className="note-input"
              placeholder="type what you want here"
              onChange={handleChange}
            />
            <button
              className="submit-button"
              disabled={!values.note}
              onClick={openModal}
            >
              SUBMIT
            </button>
            <div
              style={{
                color: "white",
                marginTop: "20px",
                display: "flex",
                alignItems: "center",
                marginLeft: "15px",
                fontSize: "20px",
                cursor: "pointer",
              }}
              onClick={handleWhatsAppClick}
            >
              <i>or WhatsApp us</i> <img src={WhatsAppLogo} width={"60px"} alt="" />
            </div>
            <div style={{ color: "white", marginTop: "20px", fontSize: "20px" }}>
              <span style={{ color: "#28fc03" }}>*</span>currently open to those in the USA
            </div>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <div style={{ display: "flex", justifyContent: "right" }}>
              <button className="modal-close-button" onClick={closeModal}>
                &times;
              </button>
            </div>
            <form onSubmit={handleSubmit}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <input
                  type="text"
                  name="name"
                  className="modal-input"
                  placeholder="Name"
                  value={values.name}
                  onChange={handleChange}
                />
                <input
                  required
                  type="email"
                  name="email"
                  className="modal-input"
                  placeholder="Email *"
                  value={values.email}
                  onChange={handleChange}
                />
                <input
                  type="number"
                  name="phone"
                  className="modal-input"
                  placeholder="Mobile"
                  value={values.phone}
                  onChange={handleChange}
                />
                <input
                  type="text"
                  name="city"
                  className="modal-input"
                  placeholder="City"
                  value={values.city}
                  onChange={handleChange}
                />
                <input
                  type="text"
                  name="state"
                  className="modal-input"
                  placeholder="State"
                  value={values.state}
                  onChange={handleChange}
                />
                <button
                  type="submit"
                  className="submit-button"
                  disabled={isLoading}
                >
                  {isLoading ? "LOADING..." : "SUBMIT"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default App;
