import React from "react";
import { Route, Routes } from "react-router-dom";
// import { ToastContainer } from "react-toast";
import Landing from "./views/landing";
import "./App.css";

function App() {
  return (
    <>
      {/* <ToastContainer delay={5000} /> */}
      <Routes>
        <Route path="/" element={<Landing />} />
      </Routes>
    </>
  );
}

export default App;
